@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800&display=swap");

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("./assets/fonts/Gilroy-Light.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Light.woff") format("woff"),
    url("./assets/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  color: var(--black);
}

@font-face {
  font-family: "Gilroy Bold";
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("./assets/fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Extrabold.woff") format("woff"),
    url("./assets/fonts/Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Gilroy, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--base) !important;
}

:root {
  --black: #000000;
  --white: #f1f1f1;
  --white-w: rgb(255, 255, 255);
  --light: #f1f1f1;
  --light-dark: #ebebeb;
  --transparent: #ffffff00;
  --red: red;
  --orange: orange;
  --green: green;
  --pink: #fd81a8;
  --base: #feefe7;
  --base-h: #fedecd;
}

a {
  text-decoration: none !important;
}

h1 {
  font-family: Gilroy;
  letter-spacing: 0.3rem;
  font-size: 2rem !important;
  font-weight: 1000 !important;
}

h3 {
  font-size: 1.5rem !important;
  letter-spacing: 0.15rem;
}

h5 {
  font-size: 1.2rem !important;
  letter-spacing: 0.05rem;
  white-space: nowrap;
}

.text-pricing td {
  margin: 0;
}

footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.eapps-widget-toolbar {
  display: none !important;
}

footer a.tomas {
  color: var(--pink);
  font-weight: bold;
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

.logo-nav {
  width: 15rem;
  transition: 0.2s;
}

.logo-nav-invisible {
  width: 15rem;
  transition: 0.2s;
  visibility: hidden;
}

.logo-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 27rem;
  transition: 0.2s;
}

.logo-nav.active {
  width: 10rem;
  transition: 0.2s;
}

.intro-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

* {
  font-family: Gilroy, sans-serif;
  font-size: 1.1rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--pink);
  border-radius: 10px;
  border: 2px solid var(--base);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--base) !important;
}

header {
  padding: 1rem 5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 50;
  transition: 0.2s;
}

header.active {
  background-color: var(--base);
  transition: 0.3s;
  padding-bottom: 1rem;
  margin-top: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

nav {
  display: flex;
  gap: 10px;
}

.spacer {
  padding-top: 6.5rem;
  position: relative;
  z-index: 0;
}

.spacer.mobile {
  display: block;
  visibility: visible;
  padding-top: 6.5rem;
  position: relative;
  z-index: 0;
}

@keyframes in-keyframes {
  0% {
    opacity: 0;
    translate: 0 100%;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes out-keyframes {
  0% {
    opacity: 1;
    translate: 0 0;
  }
  100% {
    opacity: 0;
    translate: 0 100%;
  }
}

.animate-in {
  animation-name: in-keyframes;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animate-out {
  animation-name: out-keyframes;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* wave link styles */
body .react-bubbly-transitions__bubbly-link {
  padding: 0.5rem 0.75rem;
  outline: none;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  color: var(--black);
}

body .react-bubbly-transitions__bubbly-link:hover {
  padding: 0.5rem 0.75rem;
  outline: none;
  letter-spacing: 0.1rem;
  color: var(--pink);
  transition: 0.2s;
}

body .react-bubbly-transitions__bubbly-link.active {
  position: relative;
  color: var(--pink);
  font-weight: 1000;
}

body .react-bubbly-transitions__bubbly-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background: var(--pink);
}

form.kontakt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
  width: 100%;
}

.error-input {
  margin-bottom: 1rem;
  color: var(--red);
  font-weight: 100;
}

label.label-input.form-label {
  margin-bottom: 0;
}

.label-input {
  color: var(--black);
  font-weight: bold;
}

.kontakt input,
.kontakt textarea,
.login-table input,
select {
  border: 0;
  border-bottom: 1px solid #000;
  background-color: var(--base);
  color: var(--black);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  z-index: 0;
  font-family: "Gilroy";
  width: 100%;
  margin-bottom: 1rem;
}

.kontakt input:focus {
  border: 0;
  border-bottom: 1px solid #000;
}

.navbar-color {
  background-color: var(--base) !important;
  transition: 0.3s;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.send-button {
  margin: 1rem 0 0 0;
  border: 1.5px solid var(--pink);
  background-color: var(--transparent);
  color: var(--black);
  padding: 0.75rem 2rem 0.75rem 2rem;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  z-index: 0;
  font-family: "Gilroy";
  width: 100%;
  text-align: center;
  border-radius: 2.5rem;
}

.send-button:disabled:hover {
  background-color: var(--base);
  color: var(--black);
  border: 1.5px solid var(--base);
  cursor: not-allowed;
}

.orange {
  margin: 1rem 0 0 0;
  border: 1.5px solid orange;
  background-color: orange;
  color: var(--black);
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  z-index: 0;
  font-family: "Gilroy";
  width: auto;
  text-align: center;
  border-radius: 2.5rem;
  font-weight: bold;
}

.green {
  margin: 1rem 0 0 0;
  border: 1.5px solid green;
  background-color: green;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  z-index: 0;
  font-family: "Gilroy";
  width: auto;
  text-align: center;
  border-radius: 2.5rem;
}

.send-button:hover {
  background-color: var(--pink);
  color: var(--white-w);
  cursor: pointer;
}

.kontakt {
  align-items: flex-start !important;
}

.kontakt-info,
.kontakt-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1rem;
}

.kontakt-desc {
  margin-left: 1rem;
}

.kontakt-desc h3 {
  margin-bottom: 1rem;
}

.kontakt-desc span {
  font-size: 1.15rem;
  margin: 0.5rem 0;
}

.kontakt-desc span.first {
  font-size: 1.15rem;
  margin: 1rem 0 0.5rem 0;
}

.kontakt-desc span a {
  font-size: 1.15rem;
  color: inherit;
  text-decoration: none;
}

.kontakt-desc span a:hover {
  color: var(--pink);
  text-decoration: none;
  font-weight: bold;
}

.kontakt-desc span i,
h3.kontakt-heading.form i {
  font-size: 1.25rem;
  margin-right: 1rem;
}

h1 i {
  font-size: 1.75rem;
  margin-right: 1rem;
}

.content-page-items {
  margin: 2rem 0;
}

.content-page {
  margin: 0rem 0;
}

.content-page.blog {
  margin: 0rem 0;
}

.content-page.types {
  margin: 0rem 0;
}

.types-desc {
  padding-right: 2rem;
}

.types-desc h1 {
  margin-bottom: 0.5rem !important;
}

.types-desc h4 {
  margin: 0 0 2rem 0;
}

.content-page h1 {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.kontakt-desc h3 i {
  font-size: 1.15rem;
  margin-right: 1rem;
}

.kontakt-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.aboutphoto {
  width: 20rem;
  height: 30rem;
  object-fit: cover;
  object-position: center;
  margin: 0.5rem;
  border-radius: 2.5rem;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.card-head {
  font-weight: 1000;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.card-content {
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 2.5rem;
  text-align: center;
  margin: 2rem 0;
  overflow: visible;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.card-content.portfo {
  position: relative;
  width: 20rem;
  height: auto;
  border-radius: 2.5rem;
  text-align: center;
  margin: 2rem 0;
  overflow: visible;
  box-shadow: none;
}

.card-content.portfo p {
  color: var(--black);
  font-size: 1.25rem !important;
  height: auto;
  letter-spacing: 0.15rem;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  border-radius: 3rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 1.5rem;
  margin: 0.5rem 0;
  background-color: var(--base-h);
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.card-image-content:hover {
  filter: grayscale(100%);
  transition: 0.7s;
  position: inherit;
  z-index: 25;
}

.card-image-content.blog:hover {
  filter: grayscale(0%) !important;
  position: inherit;
  z-index: 25;
}

.card-content-pricing {
  position: relative;
  width: 100%;
  height: 90%;
  border-radius: 2.5rem;
  text-align: center;
  margin: 2rem 0;
  background-color: var(--base-h);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.card-content h3 {
  text-transform: uppercase;
  transition: 0.7s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 1000;
  color: var(--white-w);
  visibility: hidden;
  animation-name: out-keyframes;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

.card-content-pricing h3 {
  text-transform: uppercase;
  font-weight: 1000;
  color: var(--black);
  text-decoration: none;
}

.card-content-pricing h4 {
  margin-bottom: 3rem;
}

.card-content:hover h3 {
  text-transform: uppercase;
  transition: 0.7s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 1000;
  color: var(--pink);
  z-index: 30;
  animation-name: in-keyframes;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  visibility: visible;
}

.text-pricing {
  display: flex;
  flex-flow: column nowrap;
  text-align: start;
  padding: 2rem 5rem;
  height: 100%;
  width: 100%;
}

.text-pricing a {
  margin-top: auto;
}

.text-pricing h4 {
  font-weight: 1000;
  font-size: 1.25rem;
}

.card-button {
  width: 100%;
}

.types-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.card-content .card-image-content {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border-radius: 2.5rem;
  transition: 0.3s;
  border: 0px solid var(--pink);
  transition: transform 0.5s ease;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.card-content:hover .card-image-content {
  cursor: pointer;
  transform: scale(1.15);
}

.card-content.portfo:hover .card-image-content {
  cursor: pointer;
  transform: scale(1);
}

.card-content-pricing .card-image-content {
  width: 40%;
  height: 100%;
  object-fit: cover;
  border-radius: 2.5rem;
  transition: 0.7s;
  border: 0px solid var(--pink);
}

.card a .send-button {
  margin: 1rem;
  margin-top: auto;
}

.page-header,
.page-header-blog,
.page-header-pricing {
  position: relative;
}

.image-container {
  position: relative;
  width: auto;
  height: 15rem;
  object-fit: cover;
  border-radius: 2.5rem;
  transition: 0.3s;
  border: 0px solid var(--pink);
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.image-container.no-radius {
  position: relative;
  width: auto;
  height: 15rem;
  object-fit: cover;
  border-radius: 0;
  transition: 0.3s;
  border: 0px solid var(--pink);
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.image-container .type-photo {
  width: 100%;
  height: 15rem;
  border-radius: 2.5rem;
  object-fit: cover;
  border: 0px solid var(--pink);
  transition: transform 0.5s ease;
}

.image-container.no-radius .type-photo {
  width: 100%;
  height: 15rem;
  border-radius: 0;
  object-fit: cover;
  border: 0px solid var(--pink);
  transition: transform 0.5s ease;
}

.image-container:hover .type-photo {
  cursor: pointer;
  transition: transform 0.5s ease;
  transform: scale(1.15);
}

.image-container.no-radius:hover .type-photo {
  cursor: pointer;
  transition: transform 0.5s ease;
  transform: scale(1.15);
}

.type-photo.type {
  width: 100%;
  border-radius: 2.5rem;
}

.type-photo.type:hover {
  cursor: context-menu;
  transition: 0.3s;
  border: 0px solid var(--pink);
}

.image-gallery-types {
  margin: 3rem 0;
}

p.text-muted {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.slider {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.slides {
  height: 100vh;
  width: 100%;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 100%;
  transition: 1s;
}

.slide img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

/*css for manual slide navigation*/

.navigation-manual {
  position: absolute;
  width: 100%;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  display: none;
}

.manual-btn {
  border: 2px solid var(--pink);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  display: none;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: var(--pink);
}

#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -100%;
}

#radio3:checked ~ .first {
  margin-left: -200%;
}

#radio4:checked ~ .first {
  margin-left: -300%;
}

/*css for automatic navigation*/

.navigation-auto {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 460px;
}

.navigation-auto div {
  border: 2px solid var(--pink);
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
  display: none;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
  background: var(--pink);
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: var(--pink);
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
  background: var(--pink);
}

#radio4:checked ~ .navigation-auto .auto-btn4 {
  background: var(--pink);
}

.desktop {
  display: block;
  visibility: visible;
}

.mobile {
  display: none;
  visibility: hidden;
}

.navmenu-flex-basket {
  visibility: hidden;
  display: none;
}

.hamburger-react {
  display: none;
  visibility: hidden;
}

.instagram-gallery {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.instagram-item {
  -webkit-box-flex: 0;
  flex: 0 0 calc(100% / 6 - 10px);
  margin: 5px;
  display: block;
  position: relative;
  transition: transform 0.5s ease;
}

.instagram-image {
  display: block;
  width: auto;
  height: 7rem;
  border-radius: 0rem;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
  transition: transform 0.5s ease;
}

.instagram-item:hover .instagram-image {
  cursor: pointer;
  transition: transform 0.5s ease;
  transform: scale(1.02);
}

@keyframes fade-in-move-right {
  0% {
    opacity: 0;
    transform: translateX(25rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-move-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(25rem);
  }
}

div#cover {
  display: none;
  visibility: hidden;
}

div#cover.covering {
  display: none;
  visibility: hidden;
}

* {
  box-sizing: border-box;
}

html,
body,
.wrapper {
  height: 100%;
}

@keyframes rotate {
  100% {
    background-position: 0% 50%;
  }
}

.control {
  border: 0;
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  background-color: var(--white-w);
  color: var(--black);
  font-family: inherit;
  font-size: 16px;
  transition: 0.4s;
}

.login-card {
  width: 400px;
  padding: 3rem 2rem;
  border-radius: 2.5rem;
  background: var(--white);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.admin-card {
  width: 300px;
  height: 21rem;
  padding: 100px 30px 64px;
  border-radius: 2.5rem;
  background: var(--white);
  text-align: center;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.login-card > h2,
.admin-card > h2 {
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 6px;
}

.login-card > h3 {
  color: #837f90;
  margin: 0 0 40px;
  font-weight: 500;
  font-size: 1rem;
}

.login-form {
  width: 100%;
  margin: 0;
  display: grid;
  gap: 16px;
}

.login-card input,
.admin-card input,
.login-form input {
  border-radius: 2.5rem;
}

.login-form input.control::placeholder,
.admin-card input.control::placeholder {
  color: #6f6e74;
}

.login-form > button.control,
.admin-card > button.control {
  cursor: pointer;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  background: transparent;
  color: var(--black);
  border: 1px solid var(--pink);
  font-family: inherit;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 2px;
  transition: all 0.375s;
  border-radius: 2.5rem;
}

.login-form > button.control:hover,
.admin-card > button.control:hover {
  background: var(--pink);
  color: var(--white-w);
  border: 1px solid var(--pink);
  transition: all 0.375s;
}

.password {
  position: relative;
}

.admin-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 2rem;
}

.admin-dashboard .admin-card {
  flex: 30%;
}

.quill {
  background-color: white;
}

.ql-editor {
  height: 70vh !important;
  width: 100%;
  overflow: scroll;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 1rem;
  width: 100%;
}

.ql-toolbar.ql-snow .ql-picker-label {
  display: flex;
  align-items: center;
}

.send-button.admin {
  position: fixed;
  top: 0.5rem;
  left: 0.75rem;
  width: auto;
  z-index: 1000;
  background-color: var(--pink);
  color: var(--white-w);
  transition: 0.3s;
  padding: 0.3rem;
  font-size: 0.75rem;
}

.send-button.admin:hover {
  position: fixed;
  top: 0.5rem;
  left: 0.75rem;
  width: auto;
  z-index: 1000;
  background-color: var(--base);
  color: var(--black);
  transition: 0.3s;
  padding: 0.3rem;
  font-size: 0.75rem;
}

.about-text * {
  margin: 0 !important;
  padding: 0 !important;
}

.image-editer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.delete-photo {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--red);
  color: var(--white-w);
  border-radius: 50%;
  transition: 0.3s;
  border: 0;
  height: 2rem;
  width: 2rem;
}

.delete-photo:hover {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--color-inverse-hex-06);
  color: var(--white-w);
  border-radius: 50%;
  transition: 0.3s;
  border: 0;
  height: 2rem;
  width: 2rem;
}

a.editor-button.right {
  margin-left: auto;
}

a.editor-button.left {
  margin-right: auto;
}

.send-button.editor {
  width: auto;
  margin-top: 0;
  margin-bottom: 1rem;
}

tr.my-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  border: 1px solid var(--black);
  padding: 0.5rem;
}

tr.my-list.head td {
  font-weight: bold;
  padding: 0.5rem;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

td.id {
  flex: 5%;
}

td.title {
  flex: 40%;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  margin-left: auto;
  flex: 10%;
}

.icons td a {
  color: var(--black) !important;
}

.icons td a:hover {
  color: var(--color-hex-03) !important;
}

i:hover {
  cursor: pointer;
}

.list-img {
  width: auto;
  height: 3rem;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--pink);
  color: var(--pink);
  box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
    10014px 0 0 0 var(--pink);
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px -10px 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px -10px 0 0 var(--pink);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--pink), 9999px 0 0 0 var(--pink),
      10014px 0 0 0 var(--pink);
  }
}

img.page-img-header {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  position: relative;
}

.admin img.page-img-header {
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  position: relative;
}

.page-header h1,
.page-header-blog h1,
.page-header-pricing h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white-w);
  font-weight: 1000;
  font-size: 3rem !important;
  transform: translate(-50%, -50%);
  letter-spacing: 0.25rem;
}

.carousel.slide {
  height: 100vh !important;
  width: 100% !important;
}

.carousel-caption.d-none.d-md-block p,
.carousel-caption.d-none.d-md-block h3 {
  display: none;
  visibility: hidden;
}

@media screen and (max-width: 991px) {
  .sidebar {
    display: none;
    animation: fade-in-move-left 0.3s;
    visibility: hidden !important;
  }

  img.page-img-header {
    width: 100vw;
    height: 20vh !important;
    object-fit: cover;
    position: relative;
  }

  .login-card {
    width: 80vw;
    padding: 3rem 2rem;
    border-radius: 2.5rem;
    background: var(--white);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
  }

  .sidebar.active {
    height: 100%;
    width: 100% !important;
    position: fixed;
    top: 0;
    z-index: 55;
    left: 5rem;
    padding: 5rem 0 0 2rem;
    background-color: var(--base-h);
    color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
    animation: fade-in-move-right 0.3s;
    visibility: visible !important;
  }

  .sidebar-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .sidebar-list button {
    margin: 0.5rem 1.5rem;
  }

  .hamburger-react {
    cursor: pointer;
    height: 55px;
    position: relative;
    transition: all 0.5s cubic-bezier(0, 0, 0, 1) 0s;
    user-select: none;
    width: 55px;
    outline: none;
    z-index: 100;
    visibility: visible !important;
    display: block;
    transition: all 0.3s;
    margin-left: 1rem;
    margin-top: 0.25rem;
    display: block;
    visibility: visible;
  }

  .desktop {
    display: none;
    visibility: hidden;
  }

  header {
    height: 5rem;
    padding: 0.75rem 5% 0;
  }

  div#cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    transition: all 0.5s;
    visibility: hidden;
  }

  div#cover.covering {
    display: block;
    height: 100%;
    width: 100%;
    z-index: 30;
    background-color: rgba(0, 0, 0, 0.432);
    transition: all 0.5s;
    visibility: visible !important;
  }

  .mobile {
    display: block;
    visibility: visible;
  }

  .navmenu-flex-basket {
    visibility: visible;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 1rem;
    right: 2rem;
    z-index: 200;
  }

  .card-image-content {
    width: 100% !important;
  }

  .text-pricing {
    padding: 2rem 3rem;
  }

  .card-content-pricing {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 2.5rem;
    text-align: center;
    margin: 1rem 0;
    background-color: var(--base-h);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
  }

  .card-image-content {
    filter: grayscale(0%);
    transition: 0.7s;
  }

  .card-image-content.blog {
    filter: grayscale(0%);
  }

  .content-page h1 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .content-page-items,
  .image-gallery-types {
    margin-top: 1rem !important;
  }

  .types-cards .card-content {
    margin: 1rem 0;
  }

  .card-content h3 {
    text-transform: uppercase;
    transition: 0.7s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 1000;
    color: var(--pink);
    animation-name: in-keyframes;
    animation-duration: 0.7s;
    animation-fill-mode: both;
    visibility: visible;
  }

  .image-container .type-photo {
    width: auto !important;
    height: 15rem;
    border-radius: 2.5rem;
    object-fit: cover;
    border: 0px solid var(--pink);
    transition: transform 0.5s ease;
  }

  .spacer.mobile.portfolio {
    padding-top: 5rem;
    position: relative;
    z-index: 0;
  }

  .send-button {
    margin-bottom: 1rem;
  }

  .spacer.mobile.main {
    padding-top: 5.5rem;
    position: relative;
    z-index: 0;
  }

  .types-desc {
    padding-right: 0;
  }

  .about-mobile {
    margin-top: 1rem;
  }

  .aboutphoto {
    width: 15rem;
    height: 22rem;
  }

  .kontakt-info,
  .kontakt-desc {
    margin-left: 0;
  }

  .kontakt-desc {
    margin-top: 1rem;
  }

  .admin-card {
    width: 300px;
    height: auto !important;
    padding: 100px 30px 64px;
    border-radius: 2.5rem;
    background: var(--white);
    text-align: center;
    box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
  }
}

@media screen and (min-width: 991px) {
  .spacer.mobile.main.absolute {
    padding-top: 6.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 30;
    background-color: var(--base);
  }
}

div.text-invite {
  position: absolute;
  bottom: 5rem;
  left: 10rem;
  z-index: 100;
  background-color: var(--base);
  color: black;
  font-size: 1.05rem;
  border-radius: 2.5rem;
  padding: 1rem 2rem;
  font-weight: bold;
  width: 30vw;
}

@media screen and (max-width: 726px) {
  div.text-invite {
    display: none;
    position: relative !important;
    left: 2rem;
    z-index: 20;
    background-color: var(--pink);
    color: black;
    font-size: 1.05rem;
    border-radius: 2.5rem;
    padding: 1rem 2rem;
    font-weight: bold;
    width: 100%;
  }

  .instagram-image {
    display: block;
    width: auto;
    height: 5.5rem;
    border-radius: 0rem;
    box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
    transition: transform 0.5s ease;
  }
}

.gallery__image {
  background-color: var(--pink-h);
  display: inline-block;
  margin: 0 0 1em;
  position: relative;
  width: 100%;
}

.image-gallery-types {
  margin: 1.5em 0;
  padding: 0;
  column-count: 1;
  column-gap: 1.5em;
  font-size: 0.85em;
  margin-top: 3rem;
}

.image-gallery-types img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 15px 0 rgba(129, 128, 128, 0.705);
}

.image-gallery-types img:hover {
  cursor: pointer;
}

.custom-file-input {
  border: 2px dashed var(--pink);
  padding: 3rem;
}

input#file-upload-button {
  margin: 1rem 0 0 0;
  border: 1.5px solid var(--pink);
  background-color: var(--transparent);
  color: var(--black);
  padding: 0.75rem 2rem 0.75rem 2rem;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: 0.2s;
  z-index: 0;
  font-family: "Gilroy";
  width: 100%;
  text-align: center;
  border-radius: 2.5rem;
}

@media screen and (max-width: 480px) {
  .image-gallery-types {
    margin: 1.5em 0;
    padding: 0;
    column-count: 2;
    column-gap: 0.5em;
    font-size: 0.85em;
    margin-top: 3rem;
  }
}

.switch {
  display: inline-block;
}

.switch input {
  display: none;
}

.switch label {
  display: block;
  width: 60px;
  height: 30px;
  padding: 3px;
  border-radius: 15px;
  border: 2px solid #7e8382;
  cursor: pointer;
  transition: 0.3s;
}

.switch label::after {
  content: "";
  display: inherit;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background: #7e8382;
  transition: 0.3s;
}

.switch input:checked ~ label {
  border-color: #25c89c;
}

.switch input:checked ~ label::after {
  translate: 30px 0;
  background: #25c89c;
}

.switch input:disabled ~ label {
  opacity: 0.35;
  cursor: not-allowed;
}

.switch.square label,
.switch.square label::after {
  border-radius: 0;
}